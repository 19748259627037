import React from 'react'
import Check from '../images/check.svg';

const PaymentSuccess = ({}) => {

    return (
        <form>
            <div 
                className='form__group paymentMessage'
                style={{
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center'
                }}
            >
                <img 
                    src={Check} 
                    alt='Check icon' 
                    style={{
                        width: '100px',
                        margin: 'auto',
                    }}
                />
                <div 
                    className="form__title"
                    style={{
                        marginBottom: '30px'
                    }}
                >
                    <h2
                        style={{
                            color: 'var(--colour__primary)'
                        }}
                    >Thank You.</h2>
                </div>
                <p
                    style={{
                        marginBottom: '30px',
                        paddingBottom: '10px',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}
                >
                    Your payment has been successfully processed.
                </p>
            </div>
        </form>
    )
    }

export default PaymentSuccess