import React from 'react';
import Check from '../images/xIcon.svg';

const PaymentUnsuccessful = ({setPaymentResult}) => {

    const resetPaymentResult = () => {
        setPaymentResult('')
    }

    return (
        <form>
            <div 
                className='form__group paymentMessage'
                style={{
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center'
                }}
            >
                <img 
                    src={Check} 
                    alt='Check icon' 
                    style={{
                        width: '100px',
                        margin: 'auto',
                    }}
                />
                <div className="form__title">
                    <h2>Payment Unsuccessful</h2>
                </div>
                <p
                    style={{
                        marginBottom: '30px',
                        paddingBottom: '10px',
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }}
                >
                    Please check your payment card details and try again.
                </p>
                <button
                    type="button" 
                    className="form__action" 
                    onClick={resetPaymentResult}
                    style={{
                        margin: 'auto'
                    }}
                >
                    Try Again
                </button>
            </div>
        </form>
    )
}

export default PaymentUnsuccessful