import './css/styles.css';

import API                                      from 'iprs-api-library';
import { getURLSearchParam, decodeJWT  }        from 'iprs-react-library';
import { useState, useEffect }     from 'react';
import FormWrapper from './components/FormWrapper';

const apiURL = process.env.REACT_APP_IPRS_WEB_API_URL;
const api = new API(null,apiURL);

const App = () => {

    const [err, setErr] = useState('');

    useEffect(()=>{
        const token = getURLSearchParam('Token');
        api.validateLoggedIn(token)
            .then( token => {
                console.log('token', token);
                const decodedToken = decodeJWT(token);
                console.log('decodedToken', decodedToken);
            }).catch(e => setErr( e.apiText));
    }, []);

    return <>
        <div className="App">

            <div className="container">
                <header className="topHeader main__top">
                    <div className="main__top-logo">
                        {/* 
                            Image file here is located in public/images directory
                        */}
                        <img src="images/iprshealth.svg" alt="IPRS Health" />
                    </div>

                </header>
                
                <div className="header__supplementary">
                </div>

                <div className="typography">
                    <main className="Main__section">
                                              
                        <br />
                        <br />
                        {/* Error Message */}
                        {/* {console.log('error:', err ? err : 'none')} */}
                        { err ? (<p>Application Error: {err}</p>) : (<></>) }

                        {/* Payment Message */}
                        <div id="paymentMessage">
                            <p>Welcome to IPRS Health</p>
                            <p>As part of your talking therapy service delivered in partnership with Vitality Health there may be times you become liable for costs in relation to your treatment. This could be for the following reasons:</p>
                            <p>If your insurance policy with Vitality Health is cancelled, lapsed, or expires and you continue to receive treatment, you would be responsible for the costs of the treatment from the point of no cover.</p>
                            <p>If you cancel an appointment and fail to give 24 hours' notice that you are unable to attend, or if you do not show up for an appointment, you may be charged a no show fee, this is not covered by your insurance policy, so is passed on to you.</p>
                            <p>If you have an excess on your policy or if you exceed any policy benefit limits Vitality Health will advise us of this and we will request a payment from you to cover these areas.</p>
                            <p>To streamline your onward journey we would like to validate a payment card, that we will hold securely and only use if one of the above scenarios occur during your treatment with us. To validate your card a £1.00 validation transaction is processed; however, this is refunded back to you, by IPRS Health.</p>
                            <p>If IPRS Health do debit your card, we always provide you with 5 working days' notice, by email. The email will state the reasons why we need to debit the card, the amount, and our contact details in case you have any questions.</p>
                            <p>Please provide below the card details that you would like IPRS Health to validate.</p>
                        </div>

                        <section className="main__section">

                            <FormWrapper api={api} />

                        </section>

                    </main>

                </div>

            </div>


            <footer className="footer">
                <div className="footer__content group">
                    <div className="container">
                        <div className="footer__left">
                            <p>Part of</p>
                            <a href="http://www.iprsgroup.com/" target="_blank" rel="noreferrer" className="footer__logo replace">IPRS Group</a>
                        </div>
                        <div className="footer__right">
                            <p><a href="https://www.iprshealth.com/cookie-policy/" rel="noreferrer" target="_blank">Privacy &amp; Cookies</a></p>
                            <br /><br /><br />
                            <p><small>&copy; 2019 IPRS Health Limited, Suffolk House, Bramford Road, Little Blakenham, Suffolk IP8 4JU</small></p>
                            <p><small>Website design: <a href="http://www.nakedmarketing.co.uk/" rel="noreferrer" target="_blank">Naked Marketing</a></small></p>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    </>
}

export default App;

